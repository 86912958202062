import {
  LoadingSpinner,
  LoadingTextAnimation,
  type SpinnerSize,
  type SpinnerVariant,
} from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import { type TextVariant } from "@clipboard-health/ui-react/src/Typography/Text/Text";
import type { SxProps, Theme } from "@mui/material";
import { Stack } from "@mui/material";

interface ListLoadingStateProps {
  texts: string[];
  spinnerSize?: SpinnerSize;
  spinnerVariant?: SpinnerVariant;
  interval?: number;
  textVariant?: TextVariant;
  wrapperSx?: SxProps<Theme>;
}

export function ListLoadingState(props: ListLoadingStateProps) {
  const {
    texts,
    spinnerSize = "large",
    spinnerVariant = "primary",
    interval = 4000,
    textVariant = "h5",
    wrapperSx,
  } = props;

  return (
    <Stack
      spacing={8}
      sx={mergeSxProps(
        {
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        },
        wrapperSx
      )}
    >
      <LoadingSpinner size={spinnerSize} variant={spinnerVariant} />

      <LoadingTextAnimation semibold variant={textVariant} interval={interval} texts={texts} />
    </Stack>
  );
}
