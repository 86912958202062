import { Divider } from "@clipboard-health/ui-components";
import { Box, type BoxProps, Container, useTheme } from "@mui/material";

import { getSafeAreaPaddings } from "../utils/getSafeAreaPaddings";

interface StickyFooterProps extends BoxProps {
  children: React.ReactNode;
}

// TODO: Move to cbh-core as fast follow up
export function StickyFooter(props: StickyFooterProps) {
  const { children, sx, ...rest } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.tertiary,
        ...getSafeAreaPaddings({ bottom: true, fallbackBottom: theme.spacing(4) }),
        ...sx,
      }}
      {...rest}
    >
      <Divider />
      <Container sx={{ paddingX: 4, paddingTop: 6 }}>{children}</Container>
    </Box>
  );
}
